import React from "react"

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import PageNotFoundSection from "../components/sections/PageNotFoundSection"

const NotFoundPage = () => (
  <MainContainer>
    <HeadData title='Page Not Found' />

    <PageNotFoundSection />
  </MainContainer>
)

export default NotFoundPage