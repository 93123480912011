import React from "react"
import { Link } from 'gatsby'

const PageNotFoundSection = () => (
  <div className='sm:w-11/12 md:w-11/12 lg:w-7/12 xl:w-5/12 flex flex-col items-center mt-20 mb-24 mx-auto'>
    <h1 className='text-4xl mb-2 font-extrabold text-gray-900 text-center'>404</h1>

    <h2 className='text-lg text-center mb-12'>The page you are looking for can’t be found.</h2>

    <Link to='/'>
      <button className="w-full bg-transparent hover:bg-ff-blue-9 text-ff-blue-8 hover:text-white text-2xl uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-4 px-12 md:px-8 mx-auto">
        Go To Home Page
      </button>
    </Link>
  </div>
)

export default PageNotFoundSection